import { Group, AppShell as MantineAppShell, Tooltip, ActionIcon, Menu, Text, Box, Title, UnstyledButton } from '@mantine/core';
import { formatHumanName } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';
import { useMedplumProfile, useMedplumNavigate } from '@medplum/react-hooks';
import { IconChevronDown, IconBook2 } from '@tabler/icons-react';
import cx from 'clsx';
import { ReactNode, useState } from 'react';
import { ResourceAvatar } from '@medplum/react';
import classes from './Header.module.css';
import { HeaderDropdown } from './HeaderDropdown';
import { HeaderSearchInput } from './HeaderSearchInput';

export interface HeaderProps {
  readonly pathname?: string;
  readonly searchParams?: URLSearchParams;
  readonly headerSearchDisabled?: boolean;
  readonly logo: ReactNode;
  readonly version?: string;
  readonly navbarToggle: () => void;
  readonly notifications?: ReactNode;
  readonly documentationUrl?: string;
}

export function Header(props: HeaderProps): JSX.Element {
  const profile = useMedplumProfile();
  const navigate = useMedplumNavigate();
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  return (
    <MantineAppShell.Header p={8} style={{ zIndex: 101 }}>
      <Group justify="space-between">
        <Group gap="xs">
          <UnstyledButton className={classes.logoButton} onClick={props.navbarToggle}>
            {props.logo}
          </UnstyledButton>
          <UnstyledButton className={classes.logoButton} onClick={() => {navigate('/')}}>
            <Title  size="h4">
              PathCloud
            </Title>
          </UnstyledButton>
          {!props.headerSearchDisabled && (
            <HeaderSearchInput pathname={props.pathname} searchParams={props.searchParams} />
          )}
        </Group>
        <Group gap="lg" pr="sm">
          <Group>
            <UnstyledButton className={classes.logoButton} onClick={() => {
                const windowFeatures = `width=${Math.floor(window.innerWidth * 0.75)}, height=${Math.floor(
                  window.innerHeight * 0.85
                )}`;
                window.open(props.documentationUrl, 'PathCloud Docs', windowFeatures)
              }}>
              <Box style ={{ display: 'flex', flexWrap: 'nowrap', gap: '1px' }} variant="subtle" color="gray" size="lg" aria-label={'Docs'}>
                <IconBook2 size={25} color='gray' />
                <Text fw={500} ta="center">Documentation</Text>
              </Box>
            </UnstyledButton>
          </Group>
          <Group>
          {props.notifications}
            <Menu
              width={260}
              shadow="xl"
              position="bottom-end"
              transitionProps={{ transition: 'pop-top-right' }}
              opened={userMenuOpened}
              onClose={() => setUserMenuOpened(false)}
            >
              <Menu.Target>
                {profile ? (
                  // Authenticated User Profile
                  <UnstyledButton
                    className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                    onClick={() => setUserMenuOpened((o) => !o)}
                  >
                    <Group gap={7}>
                      <ResourceAvatar value={profile} radius="xl" size={24} />
                      <Text size="sm" className={classes.userName}>
                        {formatHumanName(profile?.name?.[0] as HumanName)}
                      </Text>
                      <IconChevronDown size={12} stroke={1.5} />
                    </Group>
                  </UnstyledButton>
                ) : (
                  // Guest User Profile
                  <UnstyledButton
                    className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                    onClick={() => setUserMenuOpened((o) => !o)}
                  >
                    <Group gap={7}>
                      <Text size="sm" className={classes.userName}>Settings</Text>
                      <IconChevronDown size={12} stroke={1.5} />
                    </Group>
                  </UnstyledButton>
                )}
              </Menu.Target>
              <Menu.Dropdown>
                <HeaderDropdown version={props.version} />
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </Group>
    </MantineAppShell.Header>
  );
}