/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useMemo, useState } from 'react';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css'; //if using mantine component features
import 'mantine-react-table/styles.css'; //make sure MRT styles were imported in your app root (once)
import { MantineReactTable, useMantineReactTable, type MRT_ColumnDef } from 'mantine-react-table';
import {
  Menu,
  Paper,
  Box,
  Text,
  Title,
  ActionIcon,
  Tooltip,
  Modal,
  Button,
  Textarea,
  TextInput,
  Group,
  Checkbox,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPolaroid, IconShare, IconSend2, IconCopy } from '@tabler/icons-react';
import { StatusBadge } from '@medplum/react';
import { useMedplumPathcloudClient } from '../../hooks/useMedplumPathcloud';
// import { ReportQuestionnaire } from '../../components/diagnosticreport/ReportQuestionnaire';
import { ImagingStudyList } from './types';
import { query_ImagingStudyList_PUBLIC01 } from './queries';
// import { MEDPLUM_PROJECT_ID, PATHCLOUD_PROJECT_CLIENT_ID, PATHCLOUD_PROJECT_CLIENT_SECRET } from '../../config'
import classes from './StudyWorklist.module.css';
import {
  MEDPLUM_PROJECT_ID,
  PATHCLOUD_PROJECT_CLIENT_ID,
  PATHCLOUD_PROJECT_CLIENT_SECRET,
  PATHCLOUD_ORG_PUBLIC_01,
  PATHCLOUD_VIEWER_ROOT_URL_CLINICAL,
  PATHCLOUD_VIEWER_ROOT_URL_PACS,
} from '../../config';

function getQuery(worklistID: string, queryOrgID: string, projectID: string): string {
  let queryFilter = '';
  switch (worklistID) {
    case 'private01':
      queryFilter = `(
        _count: 100
        _compartment: "Organization/${queryOrgID}" 
      )`;
      break;
    case 'public01':
      queryFilter = `(
        _count: 100
        _tag: "tags-imagingstudy-isPublic-${projectID}|true",
      )`;
      break;
    default:
      queryFilter = `(
        _count: 100
        _tag: "tags-imagingstudy-isPublic-${projectID}|true",
      )`;
  }
  return query_ImagingStudyList_PUBLIC01.replace('$queryFilter', queryFilter);
}
const query = getQuery('public01', PATHCLOUD_ORG_PUBLIC_01, MEDPLUM_PROJECT_ID);
// console.log(query);

export function StudyWorklist_Public01(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModalShare, handlerShare] = useDisclosure(false);
  const [diagnosticreports, setDiagnosticReports] = useState([]);
  const pathcloudMedplum = useMedplumPathcloudClient();
  const [worklistSettings] = useState({
    worklists: [
      {
        id: 'public01',
        name: 'Public Org 1 Worklist',
        title: 'Public Archive 01',
        orgid: PATHCLOUD_ORG_PUBLIC_01,
        showLaunchReport: 'false',
        showLaunchActions: 'false',
      },
    ],
  });

  useEffect(() => {
    const loadGraphQL = async () => {
      // Create dedicated MedplumClient for Public Archive

      try {
        pathcloudMedplum.setBasicAuth(PATHCLOUD_PROJECT_CLIENT_ID, PATHCLOUD_PROJECT_CLIENT_SECRET);
        await pathcloudMedplum.startClientLogin(PATHCLOUD_PROJECT_CLIENT_ID, PATHCLOUD_PROJECT_CLIENT_SECRET);
        const loginPathCloudProject = pathcloudMedplum.getActiveLogin();

        // @ts-expect-error:  - LoginState type not available
        pathcloudMedplum.setActiveLogin(loginPathCloudProject);
      } catch (err) {
        console.error(err);
      }

      try {
        setIsLoading(true);
        const results = await pathcloudMedplum.graphql(query);
        // console.log('results=', results)
        setDiagnosticReports(results['data']['ImagingStudyList']);
        setIsLoading(false);
        // Clear Pathcloud_Project Login and reactivate initial User Login
        pathcloudMedplum.clear();
      } catch (error) {
        console.log(error);
      }
    };
    loadGraphQL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data: ImagingStudyList[] = diagnosticreports;
  // console.log('data=', data)

  const columns = useMemo<MRT_ColumnDef<ImagingStudyList>[]>(
    () => [
      {
        // @ts-expect-error: TODO: Fix typing of resultset; add optional
        accessorFn: (row) => `${row.identifier[0].value.replace('urn:oid:', '')}`,
        id: 'studyUID',
        header: 'StudyUID',
      },
      {
        // @ts-expect-error: TODO: Fix typing of resultset; add optional
        accessorFn: (row) => `${row?.series[0].modality.code}`,
        id: 'modality',
        header: 'Modality',
        size: 140,
        Cell: ({ row }) => (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              row.original.series[0].modality.code === 'SM' && 'Microscopy'
            }
            {
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              row.original.series[0].modality.code !== 'SM' && row.original.series[0].modality.code
            }
          </Box>
        ),
      },
      {
        accessorFn: (row) => {
          let description = 'NA';
          if (row.description === null) {
            description = 'NA';
          } else {
            // @ts-expect-error: TODO: Fix typing of resultset; add optional
            description = row.description;
          }
          return description;
        },
        id: 'studyDescription',
        header: 'Study Description',
        size: 180,
      },
      {
        // @ts-expect-error: TODO: Fix typing of resultset; add optional
        accessorFn: (row) => `${row.location.display}`,
        id: 'location',
        header: 'Locations',
        size: 180,
      },
      {
        accessorFn: (row) => `${row.imageStatus}`,
        id: 'studyStatus',
        header: 'Status',
        size: 135,
        Cell: ({ row }) => (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <StatusBadge status={row.original.imageStatus as string} />
          </Box>
        ),
      },
      {
        accessorFn: (row) => {
          //convert to Date for sorting and filtering
          // @ts-expect-error: TODO: Fix typing of resultset; add optional
          const sDay = new Date(row.started);
          sDay.setHours(0, 0, 0, 0); // remove time from date (useful if filter by equals exact date)
          return sDay;
        },
        id: 'studyDate',
        header: 'Study Date',
        size: 130,
        filterVariant: 'date-range',
        sortingFn: 'datetime',
        enableGrouping: false,
        enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      },
      {
        accessorFn: (row) => {
          //convert to Date for sorting and filtering
          // @ts-expect-error: TODO: Fix typing of resultset; add optional
          const sDay = new Date(row.meta.lastUpdated);
          return sDay;
        },
        id: 'lastUpdated',
        header: 'Last Updated',
        size: 130,
        filterVariant: 'date-range',
        sortingFn: 'datetime',
        enableGrouping: false,
        enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      },
      {
        accessorFn: (row) => `${row.id}`,
        id: 'studyID',
        header: 'ID',
      },
      {
        id: 'launchActions',
        header: 'Actions',
        enableGrouping: false,
        enableSorting: false,
        enableColumnActions: false,
        size: 70,
        Cell: () => (
          <Box>
            <Tooltip label="Sharing Disabled">
              <IconShare size={25} color="gray" />
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns: columns,
    data: data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: {
      showLoadingOverlay: isLoading,
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: false,
    enableColumnResizing: true,
    enableFacetedValues: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableHiding: true,
    enablePinning: true,
    enableRowActions: true,
    enableRowSelection: false,
    enableFullScreenToggle: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    positionActionsColumn: 'first',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Launch', //change header text
        size: 80, //make actions column wider
      },
    },
    mantinePaginationProps: {
      radius: 'sm',
      size: 'sm',
    },
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      density: 'xs',
      pagination: { pageIndex: 0, pageSize: 15 },
      columnPinning: { left: ['mrt-row-actions'] },
      columnVisibility: {
        studyID: false,
      },
      sorting: [{ id: 'lastUpdated', desc: true }],
    },
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
      onDoubleClick: () => {
        const windowFeatures = `width=${Math.floor(window.innerWidth * 0.75)}, height=${Math.floor(
          window.innerHeight * 0.75
        )}`;
        const url_hint =
          typeof medplum !== 'undefined' && medplum?.sessionDetails?.membership?.user?.display
            ? `user_hint=${medplum.sessionDetails.membership.user.display}`
            : '';
        const study_intance_uid = row?.original.identifier[0].value.replace('urn:oid:', '');
        // @ts-expect-error: TODO: Fix typing of resultset; add optional
        if (row?.original.series[0].modality?.code === 'SM') {
          // @ts-expect-error: TODO: Fix typing of resultset; add optional
          const url = `${PATHCLOUD_VIEWER_ROOT_URL_CLINICAL}/studies/${study_intance_uid}/series/${row?.original.series[0].uid}?${url_hint}`;
          window.open(url, 'PathCloud-Clinical', windowFeatures);
        } else {
          const url = `${PATHCLOUD_VIEWER_ROOT_URL_PACS}/viewer?StudyInstanceUIDs=${study_intance_uid}&${url_hint}`;
          // @ts-expect-error: TODO: Fix typing of resultset; add optional
          window.open(url, 'OHIF-PathCloud', windowFeatures);
        }
      },
    }),

    renderRowActionMenuItems: ({ row }) => (
      <>
        <Menu.Item
          onClick={() => {
            const windowFeatures = `width=${Math.floor(window.innerWidth * 0.75)}, height=${Math.floor(
              window.innerHeight * 0.85
            )}`;
            const url_hint =
              typeof medplum !== 'undefined' && medplum?.sessionDetails?.membership?.user?.display
                ? `user_hint=${medplum.sessionDetails.membership.user.display}`
                : '';
            const study_intance_uid = row?.original.identifier[0].value.replace('urn:oid:', '');
            // @ts-expect-error: TODO: Fix typing of resultset; add optional
            if (row?.original.series[0].modality?.code === 'SM') {
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              const url = `${PATHCLOUD_VIEWER_ROOT_URL_CLINICAL}/studies/${study_intance_uid}/series/${row?.original.series[0].uid}?${url_hint}`;
              window.open(url, 'PathCloud-Clinical', windowFeatures);
            }
          }}
        >
          <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '1px' }}>
            <IconPolaroid size={25} color="orange" />
            <Text fw={500} ta="center">
              Clinical Viewer
            </Text>
          </Box>
        </Menu.Item>
      </>
    ),

    renderDetailPanel: ({ row }) => (
      <Box
        style={{
          display: 'grid',
          margin: 'auto',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
        }}
      >
        <Text>ImageStudyID: {row.original.id}</Text>
        <Text>
          StudyUID:{' '}
          {
            // @ts-expect-error: TODO: Fix typing of resultset; add optional
            row.original.identifier[0].value.replace('urn:oid:', '')
          }
        </Text>
      </Box>
    ),

    mantineToolbarAlertBannerBadgeProps: { color: 'blue', variant: 'outline' },
    mantinePaperProps: { withBorder: false },
    mantineSearchTextInputProps: { placeholder: 'Search Images' },
  });

  return (
    <Paper shadow="xs" m="md" p="xs" className={classes.paper}>
      <Title mb="lg">
        {
          worklistSettings.worklists.filter(function (el) {
            return el.id === 'public01';
          })[0].title
        }
      </Title>
      <MantineReactTable table={table} />

      {/* Added Image Share Modal mockup to main page. TODO move to component */}
      <Modal opened={openModalShare} onClose={() => handlerShare.close()} title="Share Images">
        <TextInput label="Email" placeholder="Enter email address..." />
        <Textarea data-autofocus label="Message" placeholder="Add comments to recipient..." mt="md" />
        <Group justify="right">
          <Checkbox defaultChecked label="Anonymize?" variant="outline" />
          <Button leftSection={<IconCopy size={14} />} mt="md">
            Copy Link
          </Button>
          <Button leftSection={<IconSend2 size={14} />} mt="md">
            Send
          </Button>
        </Group>
      </Modal>
    </Paper>
  );
}
