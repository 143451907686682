/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css'; //if using mantine component features
import 'mantine-react-table/styles.css'; //make sure MRT styles were imported in your app root (once)
import { MantineReactTable, useMantineReactTable, type MRT_ColumnDef } from 'mantine-react-table';
import {
  Menu,
  Paper,
  Box,
  Drawer,
  Text,
  Title,
  ActionIcon,
  Tooltip,
  Modal,
  Button,
  Textarea,
  TextInput,
  Group,
  Checkbox,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconReportMedical, IconPolaroid, IconShare, IconEdit, IconCopy, IconSend2 } from '@tabler/icons-react';
import { StatusBadge, useMedplum } from '@medplum/react';
import { ReportQuestionnaire } from '../../components/diagnosticreport/ReportQuestionnaire';
import { ImagingStudyList } from './types';
import { query_ImagingStudyList } from './queries';
import classes from './StudyWorklist.module.css';
import {
  MEDPLUM_PROJECT_ID,
  PATHCLOUD_ORG_PUBLIC_01,
  PATHCLOUD_VIEWER_ROOT_URL_AISTUDIO,
  PATHCLOUD_VIEWER_ROOT_URL_CLINICAL,
  PATHCLOUD_VIEWER_ROOT_URL_PACS,
  PATHCLOUD_VIEWER_ROOT_URL_GCP,
  PATHCLOUD_DICOMSTORE_URL,
  PATHCLOUD_DICOMSTORE,
} from '../../config';

function getQuery(worklistID: string, queryOrgID: string, projectID: string): string {
  let queryFilter = '';
  switch (worklistID) {
    case 'private01':
      queryFilter = `(
        _count: 100
        _compartment: "Organization/${queryOrgID}" 
      )`;
      break;
    case 'public01':
      queryFilter = `(
        _count: 100
        _tag: "tags-imagingstudy-isPublic-${projectID}|true",
      )`;
      break;
    default:
      queryFilter = `(
        _count: 100
        _tag: "tags-imagingstudy-isPublic-${projectID}|true",
      )`;
  }
  return query_ImagingStudyList.replace('$queryFilter', queryFilter);
}

// Remove incomplete reocrds in graphql resultset
function filterImagingStudyObj(jsonObj: { imagingStudy: null }[] | null) {
  // Check if the input is an object
  if (typeof jsonObj !== 'object' || jsonObj === null) {
    return jsonObj;
  }
  jsonObj.forEach((item: { imagingStudy: null }, i: string | number) => {
    if (!Array.isArray(item.imagingStudy) && item.imagingStudy === null) {
      // @ts-expect-error: Object is possibly 'null'
      delete jsonObj[i];
    }
  });
  jsonObj = jsonObj.filter((val: any) => val);
  return jsonObj;
}

export function StudyWorklist01(): JSX.Element {
  const medplum = useMedplum();
  const [searchParams] = useSearchParams();

  const [selectedDiagnosticReport, setDiagnosticReportId] = useState<string>();
  const [selectedPatient, setPatientId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModalShare, handlerShare] = useDisclosure(false);
  const [opened, { open, close }] = useDisclosure(false, {
    onOpen: () => {
      console.log('Opened');
    },
    onClose: () => console.log('Closed'),
  });
  const [diagnosticreports, setDiagnosticReports] = useState([]);
  const [selectedWorklist, setSelectedWorklist] = useState<string>('private01');
  const [worklistSettings, setWorklistSettings] = useState({
    worklists: [
      {
        id: 'private01',
        name: 'Pracitioner Org Default Worklist',
        title: 'My Archive',
        orgid: '',
        showLaunchReport: 'true',
        showLaunchActions: 'true',
      },
      {
        id: 'public01',
        name: 'Public Org 1 Worklist',
        title: 'Public Archive 01',
        orgid: PATHCLOUD_ORG_PUBLIC_01,
        showLaunchReport: 'false',
        showLaunchActions: 'false',
      },
    ],
  });

  // Check querystring for selectedWorklist
  useEffect(() => {
    const selectedWorklist = searchParams.get('worklistID') !== null ? searchParams.get('worklistID') : 'private01';
    // @ts-expect-error: Object is possibly 'null'
    setSelectedWorklist(selectedWorklist);
    console.log('selectedWorklist=', selectedWorklist);
  }, [searchParams, selectedWorklist]);

  useEffect(() => {
    // Load Practitioner Default Org TODO: Move to global state storage (context?) (Also move function FileUploadWizard.tsx)
    async function loadPractitionerDefaultOrg() {
      const practitionerID = medplum.getProfile()?.id;
      try {
        const practitionerRoles = await medplum.searchResources('PractitionerRole', {
          practitioner: `Practitioner/${practitionerID}`,
          _filter: '(organization pr true)',
        });

        for (const record of practitionerRoles) {
          if (record.active === true) {
            const oldWorklists = worklistSettings.worklists;
            // @ts-expect-error: Object is possibly 'null'
            oldWorklists.filter(function (el) {
              return el.id === 'private01';
            })[0].orgid = record.organization?.reference.replace('Organization/', '');
            setWorklistSettings((prevState) => ({
              ...prevState,
              worklists: oldWorklists,
            }));
            // console.log('worklistSettings.worklists=', worklistSettings.worklists)
            break;
          } else {
            console.log('Private Worklist Error: No User Org Available');
          }
        }
        loadGraphQL();
      } catch (err) {
        console.log(err);
      }
    }
    async function loadGraphQL() {
      try {
        let queryOrgID = worklistSettings.worklists.filter(function (el) {
          return el.id === 'private01';
        })[0].orgid;
        switch (selectedWorklist) {
          case 'private01':
            queryOrgID = worklistSettings.worklists.filter(function (el) {
              return el.id === 'private01';
            })[0].orgid;
            break;
          case 'public01':
            queryOrgID = worklistSettings.worklists.filter(function (el) {
              return el.id === 'public01';
            })[0].orgid;
            break;
          default:
            queryOrgID = worklistSettings.worklists.filter(function (el) {
              return el.id === 'public01';
            })[0].orgid;
            setSelectedWorklist(
              worklistSettings.worklists.filter(function (el) {
                return el.id === 'public01';
              })[0].id
            );
        }
        // console.log('queryOrgID=', queryOrgID)
        const query = getQuery(selectedWorklist, queryOrgID, MEDPLUM_PROJECT_ID);
        console.log('query=', query);
        setIsLoading(true);
        let results = await medplum.graphql(query);
        results = JSON.parse(JSON.stringify(results)).data.ImagingStudyList;
        console.log('queryresults=', results);
        const filteredResults = filterImagingStudyObj(results);
        setIsLoading(false);
        // @ts-expect-error: Object is possibly 'null'
        setDiagnosticReports(filteredResults);
      } catch (error) {
        console.log(error);
      }
    }
    loadPractitionerDefaultOrg();
  }, [medplum, selectedWorklist, worklistSettings.worklists]);

  const data: ImagingStudyList[] = diagnosticreports;

  const columns = useMemo<MRT_ColumnDef<ImagingStudyList>[]>(
    () => [
      {
        // @ts-expect-error: TODO: Fix typing of resultset; add optional
        accessorFn: (row?) => `${row.subj_Patient.Patient.name[0].given[0]} ${row.subj_Patient.Patient.name[0].family}`,
        accessorKey: 'patientName',
        id: 'patientName',
        header: 'Patient',
        size: 170,
        Cell: ({ renderedCellValue }) => (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Text>{renderedCellValue}</Text>
          </Box>
        ),
      },
      {
        // @ts-expect-error: TODO: Fix typing of resultset; add optional
        accessorFn: (row) => `${row?.series[0].modality.code}`,
        id: 'modality',
        header: 'Modality',
        size: 122,
        Cell: ({ row }) => (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              row.original.series[0].modality.code === 'SM' && 'Microscopy'
            }
            {
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              row.original.series[0].modality.code !== 'SM' && row.original.series[0].modality.code
            }
          </Box>
        ),
      },
      {
        accessorFn: (row) => {
          let description = 'NA';
          if (row.description === null) {
            description = 'NA';
          } else {
            // @ts-expect-error: TODO: Fix typing of resultset; add optional
            description = row.description;
          }
          return description;
        },
        id: 'studyDescription',
        header: 'Study Description',
        size: 180,
      },
      {
        // @ts-expect-error: TODO: Fix typing of resultset; add optional
        accessorFn: (row) => `${row.location.display}`,
        id: 'location',
        header: 'Locations',
        size: 180,
      },
      {
        accessorFn: (row) => `${row.imageStatus}`,
        id: 'studyStatus',
        header: 'Status',
        size: 132,
        Cell: ({ row }) => (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <StatusBadge status={row.original.imageStatus as string} />
          </Box>
        ),
      },
      {
        accessorFn: (row) => {
          //convert to Date for sorting and filtering
          // @ts-expect-error: TODO: Fix typing of resultset; add optional
          const sDay = new Date(row.started);
          sDay.setHours(0, 0, 0, 0); // remove time from date (useful if filter by equals exact date)
          return sDay;
        },
        id: 'studyDate',
        header: 'Study Date',
        size: 130,
        filterVariant: 'date-range',
        sortingFn: 'datetime',
        enableGrouping: false,
        enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      },
      {
        accessorFn: (row) => {
          //convert to Date for sorting and filtering
          // @ts-expect-error: TODO: Fix typing of resultset; add optional
          const sDay = new Date(row.meta.lastUpdated);
          return sDay;
        },
        id: 'lastUpdated',
        header: 'Last Updated',
        size: 145,
        filterVariant: 'date-range',
        sortingFn: 'datetime',
        enableGrouping: false,
        enableColumnFilterModes: false, //keep this as only date-range filter with between inclusive filterFn
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      },
      {
        accessorFn: (row) => `${row.id}`,
        id: 'studyID',
        header: 'ID',
      },
      {
        // @ts-expect-error: TODO: Fix typing of resultset; add optional
        accessorFn: (row) => `${row.identifier[0].value}`,
        id: 'studyUID',
        header: 'StudyUID',
      },
      {
        id: 'launchReport',
        header: 'Report',
        enableGrouping: false,
        enableSorting: false,
        enableColumnActions: false,

        size: 70,
        Cell: ({ row }) => (
          <ActionIcon
            color="clear"
            onClick={() => {
              open();
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              setDiagnosticReportId(row?.original?.servicerequest[0]?.ServiceRequest?.diagnosticreport[0].id?.toString());
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              setPatientId(row?.original.subj_Patient.Patient.id.toString());
            }}
          >
            <IconReportMedical size={25} color="green" />
          </ActionIcon>
        ),
      },
      {
        id: 'launchActions',
        header: 'Actions',
        enableGrouping: false,
        enableSorting: false,
        enableColumnActions: false,
        size: 70,
        Cell: () => (
          <Box>
            <ActionIcon color="clear" onClick={() => handlerShare.open()}>
              <Tooltip label="Share">
                <IconShare size={25} color="gray" />
              </Tooltip>
            </ActionIcon>
            <ActionIcon color="clear">
              <Tooltip label="Edit">
                <IconEdit size={25} color="gray" />
              </Tooltip>
            </ActionIcon>
          </Box>
        ),
      },
    ],
    [open, handlerShare]
  );

  // Add/Remove Columns based selectedWorklistID
  if (
    worklistSettings.worklists.filter(function (el) {
      return el.id === selectedWorklist;
    })[0].showLaunchReport === 'false'
  ) {
    columns.splice(
      columns.findIndex((el) => el.id === 'launchReport'),
      1
    );
  }
  if (
    worklistSettings.worklists.filter(function (el) {
      return el.id === selectedWorklist;
    })[0].showLaunchActions === 'false'
  ) {
    columns.splice(
      columns.findIndex((el) => el.id === 'launchActions'),
      1
    );
  }

  const table = useMantineReactTable({
    columns: columns,
    data: data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: {
      showLoadingOverlay: isLoading,
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: false,
    enableColumnResizing: true,
    enableFacetedValues: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableHiding: true,
    enablePinning: true,
    enableRowActions: true,
    enableRowSelection: false,
    enableFullScreenToggle: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    positionActionsColumn: 'first',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Launch', //change header text
        size: 80, //make actions column wider
      },
    },
    mantinePaginationProps: {
      radius: 'sm',
      size: 'sm',
    },
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      density: 'xs',
      pagination: { pageIndex: 0, pageSize: 15 },
      columnPinning: { left: ['mrt-row-actions'] },
      columnVisibility: {
        studyID: false,
        studyUID: false,
      },
      sorting: [{ id: 'lastUpdated', desc: true }],
    },
    mantineTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
      },
      onDoubleClick: () => {
        const windowFeatures = `width=${Math.floor(window.innerWidth * 0.75)}, height=${Math.floor(
          window.innerHeight * 0.75
        )}`;
        const url_hint =
          typeof medplum !== 'undefined' && medplum?.sessionDetails?.membership?.user?.display
            ? `user_hint=${medplum.sessionDetails.membership.user.display}`
            : '';
        const study_intance_uid = row?.original.identifier[0].value.replace('urn:oid:', '');
        // @ts-expect-error: TODO: Fix typing of resultset; add optional
        if (row?.original.series[0].modality?.code === 'SM') {
          // @ts-expect-error: TODO: Fix typing of resultset; add optional
          const url = `${PATHCLOUD_VIEWER_ROOT_URL_CLINICAL}/studies/${study_intance_uid}/series/${row?.original.series[0].uid}?${url_hint}`;
          window.open(url, 'PathCloud-Clinical', windowFeatures);
        } else {
          const url = `${PATHCLOUD_VIEWER_ROOT_URL_PACS}/viewer?StudyInstanceUIDs=${study_intance_uid}&${url_hint}`;
          // @ts-expect-error: TODO: Fix typing of resultset; add optional
          window.open(url, 'OHIF-PathCloud', windowFeatures);
        }
      },
    }),
    renderRowActionMenuItems: ({ row }) => (
      <>
        <Menu.Item
          onClick={() => {
            const windowFeatures = `width=${Math.floor(window.innerWidth * 0.75)}, height=${Math.floor(
              window.innerHeight * 0.75
            )}`;
            // @ts-expect-error: TODO: Fix typing of resultset; add optional
            if (row?.original.series[0].modality?.code === 'SM') {
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              const url = `${PATHCLOUD_VIEWER_ROOT_URL_AISTUDIO}/${row.original.identifier[0].value.replace(
                'urn:oid:',
                ''
              )}/${
                // @ts-expect-error: TODO: Fix typing of resultset; add optional
                row?.original.series[0].uid
              }/?server=${PATHCLOUD_DICOMSTORE_URL}/${PATHCLOUD_DICOMSTORE}`;
              window.open(url, 'AI-Studio-PathCloud', windowFeatures);
            }
          }}
        >
          <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '1px' }}>
            <IconPolaroid size={25} color="blue" />
            <Text fw={500} ta="center">
              AI Viewer
            </Text>
          </Box>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            const windowFeatures = `width=${Math.floor(window.innerWidth * 0.75)}, height=${Math.floor(
              window.innerHeight * 0.75
            )}`;
            const url_hint =
              typeof medplum !== 'undefined' && medplum?.sessionDetails?.membership?.user?.display
                ? `user_hint=${medplum.sessionDetails.membership.user.display}`
                : '';
            const study_intance_uid = row?.original.identifier[0].value.replace('urn:oid:', '');
            // @ts-expect-error: TODO: Fix typing of resultset; add optional
            if (row?.original.series[0].modality?.code === 'SM') {
              const url = `${PATHCLOUD_VIEWER_ROOT_URL_PACS}/microscopy?StudyInstanceUIDs=${study_intance_uid}&${url_hint}`;
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              window.open(url, 'PACS-SM-PathCloud', windowFeatures);
            } else {
              const url = `${PATHCLOUD_VIEWER_ROOT_URL_PACS}/viewer?StudyInstanceUIDs=${study_intance_uid}&${url_hint}`;
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              window.open(url, 'PACS-PathCloud', windowFeatures);
            }
          }}
        >
          <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '1px' }}>
            <IconPolaroid size={25} color="purple" />
            <Text fw={500} ta="center">
              PACS Viewer
            </Text>
          </Box>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            const windowFeatures = `width=${Math.floor(window.innerWidth * 0.75)}, height=${Math.floor(
              window.innerHeight * 0.85
            )}`;
            const url_hint =
              typeof medplum !== 'undefined' && medplum?.sessionDetails?.membership?.user?.display
                ? `user_hint=${medplum.sessionDetails.membership.user.display}`
                : '';
            const study_intance_uid = row?.original.identifier[0].value.replace('urn:oid:', '');
            // @ts-expect-error: TODO: Fix typing of resultset; add optional
            if (row?.original.series[0].modality?.code === 'SM') {
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              const url = `${PATHCLOUD_VIEWER_ROOT_URL_CLINICAL}/studies/${study_intance_uid}/series/${row?.original.series[0].uid}?${url_hint}`;
              window.open(url, 'PathCloud-Clinical', windowFeatures);
            }
          }}
        >
          <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '1px' }}>
            <IconPolaroid size={25} color="orange" />
            <Text fw={500} ta="center">
              Clinical Viewer
            </Text>
          </Box>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            const windowFeatures = `width=${Math.floor(window.innerWidth * 0.75)}, height=${Math.floor(
              window.innerHeight * 0.85
            )}`;
            let url_hint =
              typeof medplum !== 'undefined' && medplum?.sessionDetails?.membership?.user?.display
                ? `user_hint=${medplum.sessionDetails.membership.user.display}`
                : '';
            let study_intance_uid = row?.original.identifier[0].value.replace('urn:oid:', '');

            // @ts-expect-error: TODO: Fix typing of resultset; add optional
            if (row?.original.series[0].modality?.code === 'SM') {
              // @ts-expect-error: TODO: Fix typing of resultset; add optional
              const url = `${PATHCLOUD_VIEWER_ROOT_URL_GCP}?series=${PATHCLOUD_DICOMSTORE}/studies/${study_intance_uid}/series/${row?.original.series[0].uid}&${url_hint}`;
              window.open(url, 'PathCloud-GCP', windowFeatures);
            }
          }}
        >
          <Box style={{ display: 'flex', flexWrap: 'nowrap', gap: '1px' }}>
            <IconPolaroid size={25} color="yellow" />
            <Text fw={500} ta="center">
              GCP Viewer
            </Text>
          </Box>
        </Menu.Item>
      </>
    ),
    renderDetailPanel: ({ row }) => (
      <Box
        style={{
          display: 'grid',
          margin: 'auto',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
        }}
      >
        <Text>DiagnosticReportID: {
          // @ts-expect-error: TODO: Fix typing of resultset; add optional
          row?.original?.servicerequest[0]?.ServiceRequest?.diagnosticreport[0].id?.toString()}
        </Text>
        <Text>ImageStudyID: {row.original.id}</Text>
        <Text>
          StudyUID:{' '}
          {
            // @ts-expect-error: TODO: Fix typing of resultset; add optional
            row.original.identifier[0].value.replace('urn:oid:', '')
          }
        </Text>
      </Box>
    ),

    mantineToolbarAlertBannerBadgeProps: { color: 'blue', variant: 'outline' },
    mantinePaperProps: { withBorder: false },
    mantineSearchTextInputProps: { placeholder: 'Search Images' },
  });

  return (
    <Paper shadow="xs" m="md" p="xs" className={classes.paper}>
      <Title mb="lg">
        {
          worklistSettings.worklists.filter(function (el) {
            return el.id === selectedWorklist;
          })[0].title
        }
      </Title>
      <Drawer position="right" opened={opened} onClose={close} title="Report Panel" size={'600px'}>
        <ReportQuestionnaire diagnosticreportId={selectedDiagnosticReport} patientId={selectedPatient} />
      </Drawer>
      <MantineReactTable table={table} />

      {/* Added Image Share Modal mockup to main page. TODO move to component */}
      <Modal opened={openModalShare} onClose={() => handlerShare.close()} title="Share Images">
        <TextInput label="Email" placeholder="Enter email address..." />
        <Textarea data-autofocus label="Message" placeholder="Add comments to recipient..." mt="md" />
        <Group justify="right">
          <Checkbox defaultChecked label="Anonymize?" variant="outline" />
          <Button leftSection={<IconCopy size={14} />} mt="md">
            Copy Link
          </Button>
          <Button leftSection={<IconSend2 size={14} />} mt="md">
            Send
          </Button>
        </Group>
      </Modal>
    </Paper>
  );
}
