import { Loading, Logo, NavbarMenu, useMedplum } from '@medplum/react';
import { AppShell } from './components/AppShell/AppShell';
import { Space } from '@mantine/core';
import { MEDPLUM_VERSION } from '@medplum/core';
import { UserConfiguration } from '@medplum/fhirtypes';
import {
  Icon,
  IconBrandAsana,
  IconBuilding,
  IconForms,
  IconId,
  IconLock,
  IconLockAccess,
  IconMicroscope,
  IconPackages,
  IconReceipt,
  IconReportMedical,
  // IconStar,
  IconUser,
  IconWebhook,
  Icon3dCubeSphere,
  IconImageInPicture,
  IconTableImport,
  IconBuildingHospital
} from '@tabler/icons-react';
import { Suspense } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { WSIUploadListProvider } from './context/WSIUploadListContext';
import { PATHCLOUD_DOCUMENTATION_URL } from './config';
import './App.css';

export function App(): JSX.Element | null {
  const medplum = useMedplum();
  const config = medplum.getUserConfiguration();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  if (medplum.isLoading()) {
    return null;
  }

  return (
    <WSIUploadListProvider>
      <AppShell
        logo={<Logo size={24} />}
        pathname={location.pathname}
        searchParams={searchParams}
        version={MEDPLUM_VERSION}
        menus={userConfigToMenu(config)}
        // displayAddBookmark={!!config?.id}
        displayAddBookmark={false}
        resourceTypeSearchDisabled={true}
        documentationUrl={PATHCLOUD_DOCUMENTATION_URL}
      >
        <Suspense fallback={<Loading />}>
          <AppRoutes />
        </Suspense>
      </AppShell>
    </WSIUploadListProvider>
    
  );
}
function userConfigToMenu(config: UserConfiguration | undefined): NavbarMenu[] {
  
  const menuDefault=
    {
      title: 'Lists',
      links: [
        { icon: <IconUser />, label: 'Patients', href: '/Patient' },
        { icon: <IconUser />, label: 'Worklists', href: '/worklists/studyworklist_Public01' }
      ]
    };
  const menuPublic=
    {
      links: [
        { icon: <IconUser />, label: 'Public Archive 01', href: '/worklists/studyworklist_Public01' }
      ]
    };
  const menuFooter=
    {
      title: 'Links',
      links: [
        { icon: <IconLock />, label: 'Security', href: '/security'},
      ],
    }
    
  let result = []
  // Check if UserConfiguration is Default Menu and replace if true
  if (Array.isArray(config?.menu) && config?.menu[0].title === 'Favorites' && Array.isArray(config?.menu[0].link) && config.menu[0].link.length === 6) {
    result.push(menuDefault);
  // Check if User is not logged in with no UserConfiguration
  } else if (!config?.menu) {
    result.push(menuPublic)
    result.push(
      {
      title: 'Links',
      links: [
        {
          label: 'Sign-in',
          href: '/signin',
          icon: <IconLock />,
        },
      ],
    });
  // Create UserConfiguration Menu
  } else {
    result = 
    config?.menu?.map((menu) => ({
      title: menu.title,
      links:
        menu.link?.map((link) => ({
          label: link.name,
          href: link.target as string,
          icon: getIcon(link.target as string),
        })) || [],
    })) || [];

    result.push( menuFooter);
  }
  return result;
}

const resourceTypeToIcon: Record<string, Icon> = {
  Patient: IconBuildingHospital,
  Practitioner: IconId,
  Organization: IconBuilding,
  ImagingStudy: IconImageInPicture,
  ServiceRequest: IconReceipt,
  DiagnosticReport: IconReportMedical,
  Questionnaire: IconForms,
  QuestionnaireResponse: IconForms,
  tasks: IconBrandAsana,
  AccessPolicy: IconLockAccess,
  Subscription: IconWebhook,
  batch: IconPackages,
  Observation: IconMicroscope,
  assistant: Icon3dCubeSphere,
  worklists: IconImageInPicture,
  fileupload: IconTableImport,
  "import-patient": IconTableImport
};

function getIcon(to: string): JSX.Element | undefined {
  try {
    const resourceType = new URL(to, 'https://app.medplum.com').pathname.split('/')[1];
    if (resourceType in resourceTypeToIcon) {
      const Icon = resourceTypeToIcon[resourceType];
      return <Icon />;
    }
  } catch (e) {
    // Ignore
  }
  return <Space w={30} />;
}

