// Link environment variables to config constants
export const MEDPLUM_BASE_URL = import.meta.env?.MEDPLUM_BASE_URL;
export const MEDPLUM_CLIENT_ID = import.meta.env?.MEDPLUM_CLIENT_ID; 
export const MEDPLUM_PROJECT_ID = import.meta.env?.MEDPLUM_PROJECT_ID; 
export const MEDPLUM_GOOGLE_CLIENT_ID = import.meta.env?.GOOGLE_CLIENT_ID; 
export const MEDPLUM_RECAPTCHA_SITE_KEY = import.meta.env?.RECAPTCHA_SITE_KEY; 
export const MEDPLUM_REGISTER_ENABLED = import.meta.env?.MEDPLUM_REGISTER_ENABLED; 
export const MEDPLUM_IMAGE_TRANSFORM_SVC_URL = import.meta.env?.MEDPLUM_IMAGE_TRANSFORM_SVC_URL; 
export const PATHCLOUD_PROJECT_CLIENT_ID = import.meta.env?.PATHCLOUD_PROJECT_CLIENT_ID;
export const PATHCLOUD_PROJECT_CLIENT_SECRET = import.meta.env?.PATHCLOUD_PROJECT_CLIENT_SECRET;
export const PATHCLOUD_ORG_PUBLIC_01 = import.meta.env?.PATHCLOUD_ORG_PUBLIC_01;

export const PATHCLOUD_DOCUMENTATION_URL = import.meta.env?.PATHCLOUD_DOCUMENTATION_URL || 'https://docs.gestaltcloud.com';
export const PATHCLOUD_VIEWER_ROOT_URL_AISTUDIO = import.meta.env?.PATHCLOUD_VIEWER_ROOT_URL_AISTUDIO || 'https://aistudio.poc1.gestaltcloud.com/images/dcmweb';
export const PATHCLOUD_VIEWER_ROOT_URL_CLINICAL = import.meta.env?.PATHCLOUD_VIEWER_ROOT_URL_CLINICAL || 'https://app.poc1.gestaltcloud.com/viewer-slim/studies';
export const PATHCLOUD_VIEWER_ROOT_URL_PACS = import.meta.env?.PATHCLOUD_VIEWER_ROOT_URL_PACS || 'https://app.poc1.gestaltcloud.com/viewer';
export const PATHCLOUD_VIEWER_ROOT_URL_GCP = import.meta.env?.PATHCLOUD_VIEWER_ROOT_URL_GCP || 'https://poc1.gestaltcloud.com/dpas';
export const PATHCLOUD_DICOMSTORE_URL = import.meta.env?.PATHCLOUD_DICOMSTORE_URL || 'https://healthcare.googleapis.com/v1';
export const PATHCLOUD_DICOMSTORE = import.meta.env?.PATHCLOUD_DICOMSTORE || 'projects/gcp-pathology-poc1/locations/us-west2/datasets/dicom-pathology/dicomStores/slide-dicom-store/dicomWeb';

export interface MedplumAppConfig {
  baseUrl?: string;
  clientId?: string;
  googleClientId?: string;
  recaptchaSiteKey?: string;
  registerEnabled?: boolean | string;
}

const config: MedplumAppConfig = {
  baseUrl: import.meta.env?.MEDPLUM_BASE_URL,
  clientId: import.meta.env?.MEDPLUM_CLIENT_ID,
  googleClientId: import.meta.env?.GOOGLE_CLIENT_ID,
  recaptchaSiteKey: import.meta.env?.RECAPTCHA_SITE_KEY,
  registerEnabled: import.meta.env?.MEDPLUM_REGISTER_ENABLED,
};

export function getConfig(): MedplumAppConfig {
  return config;
}

export function isRegisterEnabled(): boolean {
  // This try/catch exists to prevent Rollup optimization from removing this function
  try {
    return MEDPLUM_REGISTER_ENABLED !== false && MEDPLUM_REGISTER_ENABLED !== 'false';
  } catch {
    return true;
  }
}
export const IMAGE_TRANSFORM_SVC_URL = import.meta.env.PATHCLOUD_IMAGE_TRANSFORM_SVC_URL || 'https://image-transform-svc-26apfgw7pq-wl.a.run.app/transform';
export const IMAGE_TRANSFORM_SVC_METHOD = import.meta.env.PATHCLOUD_IMAGE_TRANSFORM_SVC_METHOD || 'wsi2dcmweb';
export const IMAGE_TRANSFORM_SVC_MAX_UPLOAD_SIZE = import.meta.env.PATHCLOUD_IMAGE_TRANSFORM_SVC_MAX_UPLOAD_SIZE || 5 * 1024 ** 3;
export const IMAGE_TRANSFORM_SVC_SUPPORTED_TYPES = import.meta.env.PATHCLOUD_IMAGE_TRANSFORM_SVC_SUPPORTED_TYPES || [
  'svs',
  'tiff',
  'tif',
  'vms',
  'vmu',
  'scn',
  'dcm',
  'zip',
  'ndpi',
  'scn',
  'bif',
  'pdf',
  'jpg',
  'jpeg',
  'png',
  'bmp',
];

// console.log('ENV', import.meta.env);