import { useMedplumProfile } from '@medplum/react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { ErrorPage } from './pages/ErrorPage';
import { ResourcePage } from './pages/ResourcePage';
import { AccountPage } from './pages/account';
import { UtilizationAndBilling } from './pages/account/UtilizationAndBilling';
import { Profile } from './pages/account/Profile';
import { SearchPage } from './pages/SearchPage';
import { SignInPage } from './pages/SignInPage';
import { EditTab } from './pages/patient/EditTab';
import { EncounterTab } from './pages/patient/EncounterTab';
import { LabsTab } from './pages/patient/LabsTab';
import { MedsTab } from './pages/patient/MedsTab';
import { PatientPage } from './pages/patient/PatientPage';
import { TasksTab } from './pages/patient/TasksTab';
import { TimelineTab } from './pages/patient/TimelineTab';
import { SecurityPage } from './pages/SecurityPage';
import { RegisterPage } from './pages/RegisterPage';
import { LandingPage } from './pages/landing';
import { SearchPatientPage } from './pages/patient/SearchPatientPage';
import { SearchImagingStudyPage } from './pages/imagingstudy/SearchImagingStudyPage';
import { FileUploadWizard } from './pages/fileupload/FileUploadWizard';
import { StudyWorklist_Public01 } from './pages/worklists/StudyWorklist_Public01';
// import { StudyWorklist_Public02 } from './pages/worklists/StudyWorklist_Public02';
// import { StudyWorklist_Project } from './pages/worklists/StudyWorklist_Project';
import { StudyWorklist01 } from './pages/worklists/StudyWorklist01';
import { LandingWorklist } from './pages/worklists/LandingWorklist';
import { CreateResourcePage } from './pages/CreateResourcePage';
import { TasksPage } from './pages/tasks';
import { ActionItem } from './pages/tasks/ActionItem';
import { ActionItems } from './pages/tasks/ActionItems';
import { AssistantPage } from './pages/AssistantPage';
import { FormCreatePage } from './pages/resource/FormCreatePage';
import { JsonCreatePage } from './pages/resource/JsonCreatePage';
import { Medication } from './pages/patient/Medication';
import { LabResult } from './pages/patient/LabResult';
import { TaskItem } from './pages/patient/TaskItem';
import { ImagesTab } from './pages/patient/ImagesTab';
import { Vitals } from './pages/patient/vitals';
import { VitalsTab } from './pages/patient/vitals/VitalsTab';
import { Measurement } from './pages/patient/vitals/Measurement';
import { ImportPatientPage } from './pages/ImportPatientPage'


export function AppRoutes(): JSX.Element {
  // const medplum = useMedplum();
  const profile = useMedplumProfile();
  return (
    <Routes>
      <Route errorElement={<ErrorPage />}>
      {profile ? (
        <>
          <Route path="/" element={<HomePage />} />
          <Route path="/signin" element={<SignInPage />} />  
          <Route path="/security" element={<SecurityPage />} />
          {/* <Route path="worklists/" element={<Worklists />}> */}
            {/* <Route index element={<Navigate replace to="/worklists/landingworklist" />} /> */}
          <Route path="worklists/landingworklist" element={<LandingWorklist />} />
          <Route path="worklists/studyworklist_Public01" element={<StudyWorklist01 />} />
          {/* <Route path="worklists/studyworklist_Project" element={<StudyWorklist01 />} /> */}
          <Route path="worklists/studyworklist" element={<StudyWorklist01 />} />
          <Route path="worklists/consultrequests" element={<StudyWorklist_Public01 />} />
          {/* <Route path="fileupload/fileuploadwizard" element={<FileUploadWizard />} /> */}
          {/* </Route> */}
          <Route path="/ImagingStudy" element={<SearchImagingStudyPage />}></Route>    
          <Route path="/Patient" element={<SearchPatientPage />}></Route> 
          <Route path="/:resourceType/new" element={<CreateResourcePage />}>
            <Route index element={<FormCreatePage />} />
            <Route path="form" element={<FormCreatePage />} />
            <Route path="json" element={<JsonCreatePage />} />
            <Route path="profiles" element={<FormCreatePage />} />
          </Route>
          <Route path="/Patient/:patientId" element={<PatientPage />}>
            <Route path="*" element={<TimelineTab />} />
            <Route path="edit" element={<EditTab />} />
            <Route path="encounter" element={<EncounterTab />} />
            <Route path="labs" element={<LabsTab />} />
            <Route path="meds" element={<MedsTab />} />
            <Route path="tasks" element={<TasksTab />} />
            <Route path="images" element={<ImagesTab />} />
            
            <Route path="timeline" element={<TimelineTab />} />
            <Route path="labs/:resultId" element={<LabResult />} />
            <Route path="meds/:medicationId" element={<Medication />} />
            <Route path="tasks/:taskId" element={<TaskItem />} />
            <Route path="vitals/*" element={<Vitals />}>
              <Route index element={<Navigate replace to="vitals" />} />
              <Route path="vitals" element={<VitalsTab />} />
              <Route path="vitals/:measurementId" element={<Measurement />} />
            </Route>
          </Route>
          <Route path="/:resourceType/:id" element={<ResourcePage />} />
          <Route path="/:resourceType/:id/_history/:versionId" element={<ResourcePage />} />
          <Route path="/:resourceType" element={<SearchPage />} />
          <Route path="fileupload/fileuploadwizard" element={<FileUploadWizard />} />
          <Route path="import-patient/*" element={<ImportPatientPage />} />
          <Route path="tasks/*" element={<TasksPage />}>
            <Route index element={<Navigate replace to="/tasks/action-items" />} />
            <Route path="action-items" element={<ActionItems />} />
            <Route path="action-items/:itemId" element={<ActionItem />} />
          </Route>
          <Route path="assistant/*" element={<AssistantPage />} />
          <Route path="account/*" element={<AccountPage />}>
            <Route index element={<Navigate replace to="/account/profile" />} />
            <Route path="profile" element={<Profile />} />
            <Route path="utilization-and-billing" element={<UtilizationAndBilling />} />
          </Route>
        </>
      ) : (
        <>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/" element={<StudyWorklist_Public01 />} /> */}
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="worklists/" element={<StudyWorklist_Public01 />}>
            <Route path="/worklists/studyworklist_Public01" element={<StudyWorklist_Public01 />} />
          </Route>
          <Route path="*" element={<Navigate replace to="/" />} />
        </>
      )}
    </Route>
  </Routes>
  );
}
